import React, { useState, useEffect } from 'react';
import { Container, InputField, OptionItem, OptionsList } from './styles';
import { sleep } from '../../../utils';

interface ItemData {
    id: string | number;
    label: string;
}

interface SearchableSelectProps {
    data: ItemData[];
    onSelectionChange: (item: ItemData) => void;
    debounceDelay?: number;
    placeholder?: string
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
    data,
    onSelectionChange,
    debounceDelay = 300,placeholder
}) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState<ItemData[]>(data);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => {
            const filtered = data.filter(item =>
                item.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredData(filtered);
        }, debounceDelay);

        return () => clearTimeout(handler);
    }, [inputValue, debounceDelay, data]);

    return (
        <Container>
            <InputField
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={async () => {
                    await sleep(100)
                    setIsFocused(false)
                }}
                placeholder={placeholder || "Search..."}
            />
            {isFocused && (
                <OptionsList>
                    {filteredData.map((item) => (
                        <OptionItem key={item.id} onClick={() => {
                            setInputValue(item.label)
                            onSelectionChange(item)
                        }}>
                            {item.label}
                        </OptionItem>
                    ))}
                </OptionsList>
            )}
        </Container>
    );
};

export default SearchableSelect;