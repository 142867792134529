import styled from 'styled-components';

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  height: 100%;
  flex-direction: column;
  background-color: #fafafa;
  box-sizing: border-box;
`;

const ContainerItem = styled.header<{ isActive?: boolean }>`
  display: flex;
  width: 100%;
  height: 5%;
  flex-direction: row;
  background-color: none;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10%;
  background-color: ${({ isActive }) => (isActive ? '#D9EDFD' : '#fff')};
  border-left: ${({ isActive }) => (isActive ? '#0B3E5B' : '#fff')} solid 4px;
`;

const RedirectItem = styled.a<{ isActive?: boolean }>`
color: ${({ isActive }) => (isActive ? '#155681' : '#6A6C71')};
font-size: 1.2rem;
font-weight: bold;

text-decoration: none;
  
  &:hover, &:focus, &:active {
    text-decoration: none;
    /* color: inherit; */
  }
`;

export { Container, ContainerItem, RedirectItem };

