import React, {  } from 'react';
import { AvatarContainer, AvatarImage, Container,  } from './styles';
// import { deleteData } from '../../../services/localStorage';
// import { useRouter } from 'next/navigation';
// import { FaBars } from 'react-icons/fa';
import { MdCalendarMonth, MdMarkunread, MdNotifications, MdOutlineSearch } from 'react-icons/md';
import photoUser from '../../../assets/user.png'
import { usePageContext } from '../../../hooks';

const UserAvatar = () => {
  return (
    <AvatarContainer>
      <AvatarImage src={photoUser} alt="User Avatar" />
    </AvatarContainer>
  );
};

const UserProfile: React.FC = () => {
  const { user } = usePageContext()

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <UserAvatar /><div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        <p>{user?.student?.name}</p>
        <p>Aluno</p>      </div>
    </div>
  )
}

const Header: React.FC = () => {
  return (
    <Container>
      <MdOutlineSearch />
      <MdCalendarMonth />
      <MdMarkunread />
      <MdNotifications />
      <UserProfile />
      {/* <Badge /> */}
    </Container>
  );
};

export default Header;
