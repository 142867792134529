import styled from 'styled-components'

const Container = styled.div`
  margin: 0;
  border: 1px solid #E8E9EB;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  padding: 18px;
  margin: 0px;
  border: none;
  border-radius: 8px;

  color: #556476;
  &:focus {
    outline: none;
  }
`;


export { StyledInput, Container };
