import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #E8E9EB;
  box-sizing: border-box;
  border-radius: 8px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 18px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  color: #556476;

  &:focus {
    outline: none;
  }
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
`;

const OptionItem = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export { Container, InputField, OptionsList, OptionItem };
