import React from 'react';
import styled, { css } from 'styled-components';

// Definindo os tipos de botão
type ButtonType = 'primary' | 'secondary' | 'tertiary';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
}

// Estilos base para todos os botões
const baseStyle = css`
  padding: 18px 0px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  width: 100%;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

// Estilos condicionais baseados no tipo de botão
const buttonTypeStyles = css<ButtonProps>`
  ${({ buttonType }) => {
    switch (buttonType) {
      case 'primary':
        return css`
          background-color: #155681;
          color: white;

          &:hover:not(:disabled) {
            background-color: #155681;
          }
        `;
      case 'secondary':
        return css`
          background-color: #6c757d;
          color: white;

          &:hover:not(:disabled) {
            background-color: #545b62;
          }
        `;
      case 'tertiary':
        return css`
          background-color: transparent;
          color: #007bff;
          border: 1px solid #007bff;

          &:hover:not(:disabled) {
            background-color: #e7f0ff;
          }
        `;
      default:
        return '';
    }
  }}
`;

const StyledButton = styled.button<ButtonProps>`
  ${baseStyle}
  ${buttonTypeStyles}
`;

const Button: React.FC<ButtonProps> = ({ buttonType, children, ...props }) => {
  return (
    <StyledButton buttonType={buttonType} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
