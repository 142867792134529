/* eslint-disable jsx-a11y/alt-text */
'use client';

import Button from '../../components/_ui/Button';
import Input from '../../components/_ui/Input';
import SearchableSelect from '../../components/_ui/SearchableSelect';
import { api} from '../../services/api';
import { useEffect, useRef, useState } from 'react';
import { Card, Container } from './styles';
import sagaz_color from '../../assets/sagaz_color.png';
import Spacer from '../../components/_ui/Spacer';
import { useNavigate } from 'react-router-dom';
import { usePageContext } from '../../hooks';

export default function Page() {
  const navigate = useNavigate();
  const {setIsLogged, setUser} = usePageContext()
  const enrollmentRef = useRef<HTMLInputElement>(null);
  const birthdayRef = useRef<HTMLInputElement>(null);
  const [selectedGym, setSelectedGym] = useState<any>(null);
  const [gyms, setGyms] = useState([]);

  useEffect(() => {
    const init = async () => {
      const response = await api.get('/gyms')
      setGyms(response.data)
    }

    init()
  }, []);

  async function handleSubmit() {

    console.log(enrollmentRef.current?.value)
    console.log(birthdayRef.current?.value)
    console.log(
      selectedGym
    )
    
    const response = await api.post('/sessions', {
      enrollment: enrollmentRef.current?.value || '12803',
      birthday: birthdayRef.current?.value || '1981-08-04',
    }, {
      headers: {
        'x-academy': selectedGym?.label || 'A3 ACADEMIA'
      }
    })

    console.log(response.data)
    console.log(response.status)
    localStorage.setItem('token', response.data.token)
    setUser(response.data)
    setIsLogged(true)
    navigate('/home')
  }

  return (
    <Container>
      <Card>
        <img src={sagaz_color} width={'50%'}/>
        <Spacer height={'1em'}/>
        <Input type="text" placeholder="Número matricula" name='enrollment' ref={enrollmentRef} />
        <Spacer height={'1em'}/>
        <Input type="date" placeholder="Data de nascimento" name='birthday' ref={birthdayRef} />
        <Spacer height={'1em'}/>
        <SearchableSelect placeholder='Selecione a academia' data={gyms} onSelectionChange={(d) => {
          console.log(d)
          setSelectedGym(d)
        }} />
        <Spacer height={'1em'}/>
        <Button buttonType="primary" type="button" onClick={() => handleSubmit()}>Entrar</Button>
      </Card>
    </Container>
  );
}