import React, { useState, forwardRef } from 'react';
import { Container, StyledInput } from './styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  controlled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ controlled = false, defaultValue = '', ...props }, ref) => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (controlled) {
        setValue(event.target.value);
      }
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <Container>
        <StyledInput
          {...props}
          ref={ref}
          value={controlled ? value : undefined}
          onChange={handleChange}
          defaultValue={!controlled ? defaultValue : undefined}
        />
      </Container>
    );
  }
);

Input.displayName = 'Input';

export default Input;
