// import { RootState } from '@/redux/store';
import {api} from '../../services/api';
import { useEffect } from 'react';
import { Container } from './styles';
import { usePageContext } from '../../hooks';
// import { useSelector } from 'react-redux';

export default function Home() {
  // const counter = useSelector((state: RootState) => state.student); // Obtém o valor do contador do estado global
  const {user} = usePageContext()

  useEffect(() => {

    // console.log(counter)
    const init = async () => {
      console.log('Page mounted HOME')
      const response = await api.get('/students/me')
      console.log(response.data)
    }

    init()
  }, [])

  return (
      <Container>
        <h1>Seja bem-vindo, {user?.student?.name}</h1>
      </Container>
  );
}