import React from 'react';
import { Container, ContainerItem, RedirectItem } from './styles';
import { MdDashboard, MdFolder, MdFactCheck, MdAccountBalanceWallet, MdPersonPin } from "react-icons/md";

import sagaz_color from '../../../assets/sagaz_color.png';
import Spacer from '../Spacer';

interface IMenuItem {
  isActive?: boolean
  Icon: any
  text: string
}

const MenuItem: React.FC<IMenuItem> = ({ isActive = false, Icon, text }) => {
  return (
    <ContainerItem isActive={isActive}>

      <Icon />
      <Spacer width='10px' />
      <RedirectItem href={`#${text}`} isActive={isActive}>{text}</RedirectItem>
    </ContainerItem>
  );
};
const Menu: React.FC = () => {
  return (
    <Container>
      <div style={{ height: '12%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img alt="Logo" src={sagaz_color} width={'50%'} />
      </div>
      <MenuItem isActive={true} Icon={() => <MdDashboard color={true ? '#155681' : '#6A6C71'} size="30" />} text="Dashboard" />
      <MenuItem isActive={false} Icon={() => <MdFactCheck color={false ? '#155681' : '#6A6C71'} size="30" />} text="Histórico" />
      <MenuItem isActive={false} Icon={() => <MdFolder color={false ? '#155681' : '#6A6C71'} size="30" />} text="Matrícula" />
      <MenuItem isActive={false} Icon={() => <MdAccountBalanceWallet color={false ? '#155681' : '#6A6C71'} size="30" />} text="Financeiro" />
      <MenuItem isActive={false} Icon={() => <MdPersonPin color={false ? '#155681' : '#6A6C71'} size="30" />} text="Perfil" />
    </Container>
  );
};

export default Menu;
